import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { VirtuosoGrid } from 'react-virtuoso';
// Context
import { useFiltersContext } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Api
import { GET_ALL_MERCH_PRODUCTS } from 'api/allMerch/queries';
// Types
import {
  GetAllMerchProducts,
  GetAllMerchProductsVariables,
  GetAllMerchProducts_getMerchProducts_entities,
} from 'api/allMerch/types/GetAllMerchProducts';
import { SearchLocation } from 'api/graphql-analytics-global-types';
// Helpers
import { parseColorString } from 'components/common2/PageWithFiltersWrapper/components/Filters/components/ColorFilter/ColorFilter';
// Components
import InfiniteScrollLoader from 'ui/InfiniteScrollLoader/InfiniteScrollLoader';
import NotFound from 'components/common/NotFound/NotFound';
import PageWithFiltersWrapper from 'components/common2/PageWithFiltersWrapper/PageWithFiltersWrapper';
import MerchListItem from 'components/common2/MerchList/components/MerchListItem/MerchListItem';
// Styles
import styles from './AllMerch.module.scss';

const PER_PAGE = 18;

const VirtuosoGridItem: React.FC<{
  item: GetAllMerchProducts_getMerchProducts_entities;
}> = React.memo(({ item }) => {
  return <MerchListItem merch={item} type="default" />;
});
VirtuosoGridItem.displayName = 'VirtuosoGridItem';

type AllMerchProps = {
  isPage?: boolean;
  noClear?: boolean;
  storeSlug?: string | undefined | null;
};

export const AllMerch = ({
  isPage = true,
  noClear = false,
  storeSlug,
}: AllMerchProps) => {
  const { filters, sorting, searchKey, search } = useFiltersContext();

  const inputVariables = useMemo(
    () => ({
      orderBy: sorting.order,
      direction: sorting.direction,
      limit: PER_PAGE,
      offset: 0,
      storeIds: [
        ...filters.athleteIds,
        ...filters.organizationIds,
        ...filters.contentCreatorIds,
      ],
      ...{ storeSlug },
      ...(filters?.priceRange?.length
        ? {
            price: {
              from: Number(filters?.priceRange[0]),
              to: Number(filters?.priceRange[1]),
            },
          }
        : {}),
      ...(filters?.genderV2?.length ? { genderV2: filters?.genderV2 } : {}),
      ...(filters?.merchType?.length ? { type: filters?.merchType } : {}),
      ...(filters?.size?.length ? { size: filters?.size } : {}),
      ...(filters?.color?.length
        ? { color: filters?.color?.map((i) => parseColorString(i)) }
        : {}),
      ...(filters?.sports?.length
        ? { sports: filters.sports.map((i) => Number(i)) }
        : {}),
      ...(searchKey &&
        search[searchKey] && {
          [searchKey]: search[searchKey],
        }),
    }),
    [filters, sorting, searchKey, search, storeSlug]
  );

  const { data, loading, fetchMore } = useQuery<
    GetAllMerchProducts,
    GetAllMerchProductsVariables
  >(GET_ALL_MERCH_PRODUCTS, {
    variables: {
      input: {
        ...inputVariables,
      },
      withFilters: true,
    },
  });

  const merch = data?.getMerchProducts?.entities || [];
  const total = data?.getMerchProducts?.total;
  const hasMore = data ? merch.length !== total : false;
  const isNotFound = !loading && total === 0;

  const fetchMoreData = () => {
    const prevOffset = data?.getMerchProducts?.offset || 0;
    const offset = prevOffset ? prevOffset + PER_PAGE : PER_PAGE;

    fetchMore({
      variables: {
        input: {
          ...inputVariables,
          offset,
        },
      },
    });
  };

  const handleOnEndReached = () => {
    if (hasMore) {
      fetchMoreData();
    }
  };

  return (
    <PageWithFiltersWrapper
      sortKey="Merch"
      title="Merch"
      loading={loading}
      filters={data?.getMerchProducts?.filters}
      isPage={isPage}
      noClear={noClear}
      searchBarLocation={SearchLocation.Merch}
    >
      {isNotFound && <NotFound title="Merch not found" />}

      {merch.length && (
        <VirtuosoGrid
          useWindowScroll
          overscan={PER_PAGE * 5}
          data={merch}
          listClassName={styles.root}
          totalCount={data?.getMerchProducts.total}
          itemContent={(index, item) => <VirtuosoGridItem item={item} />}
          endReached={handleOnEndReached}
          components={{
            Footer: () => {
              if (!hasMore) {
                return null;
              }

              return <InfiniteScrollLoader />;
            },
          }}
        />
      )}
    </PageWithFiltersWrapper>
  );
};

export default AllMerch;
