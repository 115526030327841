import { gql } from '@apollo/client';

export const GET_ALL_MERCH_PRODUCTS = gql`
  query GetAllMerchProducts(
    $input: GetMerchProductsInput
    $withFilters: Boolean = false
  ) {
    getMerchProducts(input: $input) {
      entities {
        id
        title
        slug
        mainImageUrl
        minPrice
        store {
          id
          slug
          lastName
          firstName
          storeDetails {
            id
            storeName
          }
        }
      }
      total
      offset
      limit
      filters @include(if: $withFilters) {
        price
        genderV2 {
          name
        }
        type {
          name
        }
        size {
          name
        }
        color {
          color
          colorCode
          colorCode2
        }
        sports {
          id
          name
        }
      }
    }
  }
`;

export const SEARCH_MERCH_PRODUCTS = gql`
  query SearchMerchProducts($input: GetMerchProductsInput) {
    getMerchProducts(input: $input) {
      entities {
        id
        title
      }
    }
  }
`;
