import { NextPage } from 'next';
import { getDataFromTree } from '@apollo/client/react/ssr';
// Hoc
import withApollo from 'hocs/withApollo';
// Helpers
import { getEnvLink } from 'helpers/routes';
// Context
import { FiltersProvider } from 'components/common2/PageWithFiltersWrapper/PageWithFiltersContext';
// Types
import { MerchProductOrderBy, SortDirection } from 'api/graphql-global-types';
// Layouts
import Head from 'layouts/Head/Head';
import Header from 'layouts/Header/Header';
import Footer from 'layouts/Footer/Footer';
// Components
import AllMerchContainer from 'components/AllMerch/AllMerch';

const AllMerch: NextPage = () => {
  const image = `${getEnvLink()}${'/merch.jpg'}`;

  return (
    <>
      <Head
        image={image}
        title="Athlete Exclusive Merchandise on MILLIONS.co"
        description="Shop your favorite athletes, gyms, and sports creators merchandise, exclusively on MILLIONS. Check out the latest drops, newest releases, and trending merch products. Available globally!"
      />

      <Header />

      <FiltersProvider
        storesListFilter="hasActiveMerch"
        searchKey="searchTerm"
        initialSorting={{
          direction: SortDirection.DESC,
          order: MerchProductOrderBy.Date,
        }}
      >
        <AllMerchContainer />
      </FiltersProvider>

      <Footer />
    </>
  );
};

export default withApollo(AllMerch, { getDataFromTree });
